




































import Product from "@/models/Product"
import {Component, Prop, Vue, Watch} from "vue-property-decorator"
import ConstantTool from "@/services/tool/ConstantTool"
import { getModule } from "vuex-module-decorators"
import SnackbarModule from "@/store/SnackbarModule"
import SessionModule from "@/store/SessionModule"
import LangModule from "@/store/LangModule";

@Component
export default class ProductMiniDetails extends Vue {

	@Prop() readonly product!: Product

	lang: any = getModule(LangModule).lang
	sessionModule: SessionModule = getModule(SessionModule)
	BASE_URL = ConstantTool.BASE_URL
	image: string = ""

	get rateId(): number {
		return this.sessionModule.session.user.rateId!
	}

	created() {
		this.refresh()
	}

	errorHandler() {
		this.image = "../assets/ban.svg"
		this.$forceUpdate()
	}

	refresh() {
		this.image = `${this.BASE_URL}/storage/ftp/art_${this.product.connectorId?.toLowerCase()}_1_tmb.jpg`
	}

	openProduct(product: Product) {
		this.$router.push({path: "/products/" + product.id})
	}

	@Watch('product')
	onProductChanged() {
		this.refresh()
	}

}
