








































































import {Component, Mixins, Ref, Vue, Watch} from "vue-property-decorator"
import {getModule} from "vuex-module-decorators"
import Product from "@/models/Product"
import ProductService from "@/services/ProductService"
import Options from "@/models/vue/Options"
import ConstantTool from "@/services/tool/ConstantTool"
import LangModule from "@/store/LangModule"
import ProductList from "@/components/product/ProductList.vue"
import ProductMiniDetails from "@/components/product/ProductMiniDetails.vue"
import CategoryTreeviewComponent from "@/components/CategoryTreeviewComponent.vue"
import CategoryTreeviewModule from "@/store/CategoryTreeviewModule"
import {MultipleItem} from "@/handlers/interfaces/ContentUI";
import Handler from "@/handlers/Handler";
import PaginationMixin from "@/mixins/PaginationMixin";
import ProductFiltersDialog from "@/components/dialog/ProductFiltersDialog.vue";

@Component({components: {ProductList, ProductMiniDetails, CategoryTreeviewComponent, ProductFiltersDialog}})
export default class ProductsView extends Mixins(PaginationMixin) {

    lang: any = getModule(LangModule).lang
    @Ref() readonly form!: HTMLFormElement
    products: MultipleItem<Product> = {items: [], totalItems: 0}
    selectedProduct = null
    loading: boolean = false
    search: string = ""
    enabled: Nullable<boolean> = null
    featured: Nullable<boolean> = null
    hasVideo: Nullable<boolean> = null
    blocked: Nullable<boolean> = null
    uncategorized: Nullable<boolean> = null
    animated: boolean = false
    headers = [
        {text: "Id Xgest", value: "connectorId", width: "120px"},
        {text: this.lang.image, value: "picture", width: "200px"},
        {text: this.lang.name, value: "name", width: "200px"},
        {text: this.lang.code, value: "code", width: "200px"},
        {text: this.lang.brand, value: "brand", width: "200px"},
    ]
    options: Options = new Options()
    totalItems: number = 0
    baseUrl = ConstantTool.BASE_URL
    categoryDialog: boolean = false
    categoryTreeviewModule = getModule(CategoryTreeviewModule)

    get selectedCategories() {
        return this.categoryTreeviewModule.selectedCategories
    }

    created() {
        this.refresh()
    }

    async refresh() {
        try {
            await Handler.getItems(this, this.products, () =>
                ProductService.getSudoProducts(
                    this.page - 1, this.size, this.search, this.selectedCategories,
                    this.enabled, this.featured, this.hasVideo, this.blocked, this.uncategorized
                )
            )

            this.animateAlertBox()

            this.setPageCount(this.products.totalItems!!)
        } catch (e) {
            console.log(e)
        }
    }

    animateAlertBox() {
        const self = this
        self.animated = true
        setTimeout(() => {
            self.animated = false
        }, 1000)
    }

    newQuery() {
        this.page = 1
        this.refresh()
    }

    rowClick(product: Product) {
        this.$router.push({path: "/products/" + product.id})
    }

    @Watch('loading')
    onLoadingChanged() {
        this.selectedProduct = null
    }

    @Watch('selectedProduct')
    onProductChanged() {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'})
    }

    @Watch('page')
    onPageChanged() {
        this.refresh()
    }
}
